import { IonButton, IonCol, IonGrid, IonInput, IonLabel, IonRow, useIonToast } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import styles from './Basic.module.scss'
import HeaderText from '../../../components/HeaderSection/HeaderText'
import Select from 'react-select'

//Intentional duplicate to make it easier to remove above later
import { fetchUserInfoData, putUserInfoData, fetchUserInfoOptions } from '../services'
import { validateField } from '../validations'
import GoBackBtn from '../../../components/GoBackBtn'
import { toNumber } from 'lodash'

interface OptionType {
  id: number
  label: string
  alt_label?: string
  display_order?: number
}

interface UserInfoObject {
  occupation: string
  pronoun: string
  political_view: string
  education: string
  school: string

  children: string
  religion: string

  pronoun_id: number | null
  pronoun_option?: OptionType | null

  politicalview_option_id: number | null
  politicalview_option?: OptionType | null

  education_option_id: number | null
  education_option?: OptionType | null

  vaccination_status_option_id: number | null
  vaccinated_status?: OptionType | null
  vaccination_status: string

  children_preference_type_id: number | null
  children_preference?: OptionType | null

  religion_id: number | null
  religion_option?: OptionType | null
}

interface UserInfoOptions {
  relationshipOptions: OptionType[]
  educationOptions: OptionType[]
  religionOptions: OptionType[]
  childPreferenceOptions: OptionType[]
  politicalViewOptions: OptionType[]
  genderInterestOptions: OptionType[]
  vaccinatedStatusOptions: OptionType[]
  pronounOptions: OptionType[]
}

interface OptionsListResponse {
  data: UserInfoOptions
}

export const BasicInfo: React.FC<any> = ({ next, isEditing }) => {
  // { <optionTypes>:<OptionType>[] }
  const [newOptionList, setNewOptionList] = useState<UserInfoOptions>({
    relationshipOptions: [],
    educationOptions: [],
    religionOptions: [],
    childPreferenceOptions: [],
    politicalViewOptions: [],
    genderInterestOptions: [],
    vaccinatedStatusOptions: [],
    pronounOptions: []
  })

  const [present] = useIonToast()
  const fields = [
    'occupation',
    'pronoun',
    'political_view',
    'education',
    'school',
    'vaccination_status',
    'children',
    'religion'
  ]

  const [userInfo, setUserInfo] = useState<UserInfoObject>({
    children_preference_type_id: null,
    education_option_id: null,
    politicalview_option_id: null,
    religion_id: null,
    vaccination_status_option_id: null,
    occupation: '',
    pronoun: '',
    pronoun_id: null,
    political_view: '',
    education: '',
    school: '',
    vaccination_status: '',
    children: '',
    religion: ''
  })

  const updateUserInfo = (routine: (obj: UserInfoObject) => void) => {
    const newInfo = { ...userInfo }
    routine(newInfo)
    setUserInfo(newInfo)
  }

  useEffect(() => {
    //@TODO(djw) start loading pinwheel?
    fetchUserInfoOptions()
      .then((options: OptionsListResponse) => {
        if (options.data) {
          setNewOptionList(options.data)
        }
      })
      .finally(() => {
        //@TODO(djw) stop loading pinwheel?
      })

    //@TODO(djw) start loading pinwheel?
    fetchUserInfoData().then((resp) => {
      if (resp.data) {
        setUserInfo(resp.data)
      }
    })
  }, [])

  const onEducationChange = (selected) => {
    updateUserInfo((draft) => {
      draft.education_option_id = selected.value
      draft.education_option = { id: selected.value, label: selected.label }
      //@TODO(djw) drop after August 2024
      draft.education = selected.label
    })
  }

  const updatePronoun = (selected) => {
    updateUserInfo((draft) => {
      draft.pronoun_id = selected.value
      draft.pronoun_option = { id: selected.value, label: selected.label }
      //@TODO(djw) drop after August 2024
      draft.pronoun = selected.label
    })
  }

  const updateReligionType = (selected) =>
    updateUserInfo((draft) => {
      draft.religion_id = selected.value
      draft.religion_option = { id: selected.value, label: selected.label }

      //@TODO(djw) drop after August 2024
      draft.religion = selected.label
    })

  const onPoliticalViewChange = (selected) => {
    updateUserInfo((draft) => {
      draft.politicalview_option_id = selected.value
      draft.politicalview_option = { id: selected.value, label: selected.label }
      //@TODO(djw) drop after next deploy
      draft.political_view = selected.label
    })
  }

  const onChildPreferenceChange = (selected) => {
    updateUserInfo((draft) => {
      draft.children_preference_type_id = selected.value
      draft.children_preference = { id: selected.value, label: selected.label }

      //@TODO(djw) drop after next deploy
      draft.children = selected.label
    })
  }

  const onVaccinatedStatusChange = (selected) => {
    updateUserInfo((draft) => {
      draft.vaccination_status_option_id = selected.value
      draft.vaccinated_status = { id: selected.value, label: selected.label }

      //@TODO(djw) drop after August 2024
      draft.vaccination_status = selected.label
    })
  }

  //I hate R2, like why?  why!?
  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      color: '#0D0D0D',
      backgroundColor: '#F2F2F6'
    })
  }

  const onSave = () => {
    if (validateField(fields, userInfo)) {
      //@TODO(djw) Except for occupation and school, everything else can go
      const payload = {
        occupation: userInfo.occupation,
        school: userInfo.school,
        pronoun: userInfo.pronoun,
        political_view: userInfo.political_view,
        education: userInfo.education,
        vaccination_status: userInfo.vaccination_status,
        children: userInfo.children,
        religion: userInfo.religion
      }

      if (userInfo.pronoun_id) {
        payload['pronoun_option'] = { connect: { id: userInfo.pronoun_id } }
      }

      if (userInfo.religion_id) {
        payload['religion_option'] = { connect: { id: userInfo.religion_id } }
      }

      if (userInfo.children_preference_type_id) {
        payload['children_preference'] = { connect: { id: userInfo.children_preference_type_id } }
      }

      if (userInfo.vaccination_status_option_id) {
        payload['vaccinated_status_option'] = { connect: { id: userInfo.vaccination_status_option_id } }
      }

      if (userInfo.education_option_id) {
        payload['education_option'] = { connect: { id: userInfo.education_option_id } }
      }

      if (userInfo.politicalview_option_id) {
        payload['politicalview_option'] = { connect: { id: userInfo.politicalview_option_id } }
      }

      putUserInfoData(payload).then((resp: any) => {
        //@todo stop pinwheel notifier?
        if (!resp.err) {
          next('interest')
        }
      })
    } else {
      present({
        message: 'Please update the required fields',
        color: 'danger',
        duration: 2000
      })
    }
  }

  const educationTypeOptions = newOptionList?.educationOptions?.map((li) => ({ value: li.id, label: li.label }))
  return (
    <div className={styles.basics}>
      <HeaderText
        title='Basic'
        label={`${isEditing ? 'EDIT' : 'CREATE'} YOUR PROFILE`}
      />
      <GoBackBtn />
      <div>
        <IonGrid className='ion-padding'>
          <IonRow>
            <IonCol size='12'>
              <div>
                <IonLabel className={styles.reqwarning}>
                  <span>
                    Job<span className='red'>*</span>
                  </span>
                  <span className='req_red'>* required field</span>
                </IonLabel>
                <IonInput
                  aria-label={'Job'}
                  aria-required='true'
                  autoCapitalize='words'
                  className={styles.customInput}
                  placeholder='Enter job here'
                  name='occupation'
                  type='text'
                  value={userInfo.occupation}
                  onIonChange={(e: any) =>
                    updateUserInfo((draft) => {
                      draft.occupation = e.target.value
                    })
                  }
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <div>
                <IonLabel>
                  School<span className='red'>*</span>
                </IonLabel>
                <IonInput
                  aria-label={'School'}
                  aria-required='true'
                  autoCapitalize='words'
                  className={styles.customInput}
                  placeholder='Enter school name here'
                  name='school'
                  type='text'
                  value={userInfo.school}
                  onIonChange={(e: any) =>
                    updateUserInfo((draft) => {
                      draft.school = e.target.value
                    })
                  }
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <div>
                <IonLabel>
                  Education<span className='red'>*</span>
                </IonLabel>
                <Select
                  aria-label={'Education'}
                  aria-required='true'
                  isSearchable
                  options={educationTypeOptions}
                  placeholder='Select education'
                  name='education'
                  className={styles.reactselect}
                  classNamePrefix='react-select'
                  value={{ label: userInfo.education_option?.label, value: userInfo.education_option?.id }}
                  onChange={onEducationChange}
                  styles={customStyles}
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <div>
                <IonLabel>
                  Pronoun<span className='red'>*</span>
                </IonLabel>
                <Select
                  aria-required='true'
                  aria-label='Pronoun'
                  isSearchable
                  options={newOptionList.pronounOptions.map((li: any) => {
                    return { label: li.label, value: li.id }
                  })}
                  placeholder='Select pronoun'
                  className={styles.reactselect}
                  classNamePrefix='react-select'
                  name='pronoun'
                  value={{ label: userInfo.pronoun_option?.label, value: userInfo.pronoun_option?.id }}
                  onChange={(selected) => updatePronoun(selected)}
                  styles={customStyles}
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <div>
                <IonLabel>
                  Religion<span className='red'>*</span>
                </IonLabel>
                <Select
                  aria-required='true'
                  aria-label={'Religion'}
                  isSearchable
                  options={newOptionList.religionOptions.map(({ label, id }) => ({ label, value: id }))}
                  placeholder='Select religion'
                  className={styles.reactselect}
                  classNamePrefix='react-select'
                  name='religion'
                  value={{
                    label: userInfo.religion_option?.label,
                    value: userInfo.religion_option?.id
                  }}
                  onChange={updateReligionType}
                  styles={customStyles}
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <div>
                <IonLabel>
                  Political views<span className='red'>*</span>
                </IonLabel>
                <Select
                  aria-required='true'
                  aria-label={'Political views'}
                  isSearchable
                  options={newOptionList.politicalViewOptions.map((li: any) => {
                    return { label: li.label, value: toNumber(li.id) }
                  })}
                  placeholder='Select political view'
                  className={styles.reactselect}
                  classNamePrefix='react-select'
                  name='political_view'
                  value={{ label: userInfo.politicalview_option?.label, value: userInfo.politicalview_option?.id }}
                  onChange={(selected) => onPoliticalViewChange(selected)}
                  styles={customStyles}
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <div>
                <IonLabel>
                  Children Preference<span className='red'>*</span>
                </IonLabel>
                <Select
                  aria-required='true'
                  aria-label={'Children Preference'}
                  isSearchable
                  options={newOptionList.childPreferenceOptions.map((li: any) => {
                    return { label: li.label, value: li.id }
                  })}
                  placeholder='Select children preference'
                  className={styles.reactselect}
                  classNamePrefix='react-select'
                  name='children'
                  value={{ label: userInfo.children_preference?.label, value: userInfo.children_preference?.id }}
                  onChange={(selected) => onChildPreferenceChange(selected)}
                  styles={customStyles}
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <div>
                <IonLabel>
                  Vaccination Status<span className='red'>*</span>
                </IonLabel>
                <Select
                  aria-label={'Vaccination Status'}
                  aria-required='true'
                  isSearchable
                  options={newOptionList.vaccinatedStatusOptions.map((li: any) => {
                    return { label: li.label, value: li.id }
                  })}
                  placeholder='Select vaccination status'
                  className={styles.reactselect}
                  classNamePrefix='react-select'
                  name='vaccination_status'
                  value={{ label: userInfo.vaccination_status, value: userInfo.vaccination_status }}
                  onChange={(selected) => onVaccinatedStatusChange(selected)}
                  styles={customStyles}
                />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <div className='form-footer'>
        <IonButton
          aria-label='Save your basic info'
          className={styles.customButton}
          expand='block'
          onClick={onSave}
        >
          {isEditing ? 'Save' : 'Continue'}
        </IonButton>
      </div>
    </div>
  )
}

export default BasicInfo
