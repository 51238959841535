import { type CustomerInfo, LOG_LEVEL, Purchases, type PurchasesCallbackId } from '@revenuecat/purchases-capacitor'
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency'
import { createContext, type FC, type ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { IS_IOS, IS_MOBILE } from '../consts'
import { getOwnProfile } from '../pages/MyProfile/services'

export type RevenueCatContextValue = {
  isPlus: boolean | undefined
  managementURL: string | null
  parseOwnProfile: (profile: OwnProfile) => void
}

interface OwnProfile {
  is_plus: boolean
}

export const RevenueCatContext = createContext<RevenueCatContextValue>({
  isPlus: undefined,
  managementURL: null,
  parseOwnProfile: () => {}
})

export const useRevenueCatContext = () => useContext(RevenueCatContext)

export interface RevenueCatProviderProps {
  children: ReactNode
}

export const RevenueCatProvider: FC<RevenueCatProviderProps> = ({ children }) => {
  const [isPlus, setIsPlus] = useState<boolean | undefined>(undefined)
  const [managementURL, setManagementURL] = useState<string | null>(null)

  const parseOwnProfile = useCallback((profile: OwnProfile) => {
    if (!IS_MOBILE) {
      setIsPlus(profile.is_plus)
    }
  }, [])

  const refreshOwnProfile = useCallback(() => {
    getOwnProfile().then((resp) => parseOwnProfile(resp.data))
  }, [parseOwnProfile])

  useEffect(() => {
    if (!IS_MOBILE) {
      const timer = setInterval(() => {
        refreshOwnProfile()
      }, 300000)

      return () => {
        clearInterval(timer)
      }
    }
  }, [refreshOwnProfile])

  useEffect(() => {
    if (IS_MOBILE) {
      let listenerId: PurchasesCallbackId | null = null

      const parseCustomerInfo = (customerInfo: CustomerInfo) => {
        setIsPlus(!!customerInfo?.entitlements?.active?.plus)
        setManagementURL(customerInfo?.managementURL)
      }

      ;(async () => {
        let canTrack: boolean

        if (IS_IOS) {
          // we have to ask for app tracking on IOS
          let att = await AppTrackingTransparency.getStatus()

          if (att.status === 'notDetermined') {
            att = await AppTrackingTransparency.requestPermission()
          }

          canTrack = att.status === 'authorized'
        } else {
          canTrack = true
        }

        const user = await getOwnProfile()

        try {
          await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG })
          await Purchases.configure({
            apiKey: IS_IOS ? 'appl_fouqfOlGAmwxQhrEqZjeyjEKKQQ' : 'goog_qXxYxDpXbokYBPSqQABThgLmnNG',
            appUserID: user.data.id
          })

          if (canTrack) {
            await Purchases.collectDeviceIdentifiers()
          }

          await Purchases.setAttributes({
            $displayName: `${user.data.first_name} ${user.data.last_name}`,
            $email: user.data.email,
            $fcmTokens: user.data.fcm_token
          })

          const { customerInfo } = await Purchases.getCustomerInfo()

          parseCustomerInfo(customerInfo)

          listenerId = await Purchases.addCustomerInfoUpdateListener(parseCustomerInfo)
        } catch (e) {
          console.error('[revenuecat] error', e)
        }
      })()

      return () => {
        if (listenerId) {
          Purchases.removeCustomerInfoUpdateListener(listenerId).catch((e) => console.error('[revenuecat] error', e))
        }
      }
    } else {
      refreshOwnProfile()
    }
  }, [parseOwnProfile, refreshOwnProfile])

  return (
    <RevenueCatContext.Provider
      value={useMemo(() => ({ isPlus, managementURL, parseOwnProfile }), [isPlus, managementURL, parseOwnProfile])}
    >
      {children}
    </RevenueCatContext.Provider>
  )
}
