import { IonCard, IonCardContent, IonCardHeader, IonImg, IonCardTitle, useIonRouter } from '@ionic/react'
import styles from './SwipeCard.module.scss'
import { handleIfValidInteraction } from '../util/a11yHelper'
import { formatUserDistance } from './util'

export default function SwipeCard({ profile, index, onSkip, onLike }) {
  const router = useIonRouter()

  const handleSkip = (e) => handleIfValidInteraction(e, () => onSkip(profile, index))

  const handleLike = (e) => handleIfValidInteraction(e, () => onLike(profile, index))

  const routeToProfile = (e) =>
    handleIfValidInteraction(e, () => {
      router.push(`/home/user/${profile.id}`)
    })
  return (
    <IonCard className={styles.swipeCard}>
      <div className={styles.basicFrame}>
        <IonImg
          role='link'
          tabIndex={0}
          className={styles.profileImg}
          aria-label={`Profile picture of ${profile.first_name}. Click to view profile`}
          onClick={routeToProfile}
          src={(profile?.UserPhotos && profile?.UserPhotos[0]?.photo_url) || '/assets/images/sarah-img.svg'}
          alt='Profile Image'
        />
      </div>
      <IonCardHeader>
        <IonCardTitle
          color='primary'
          className={styles.titleblock}
        >
          {profile.first_name}, {profile.UserInfo.age}
          {profile.is_verified && (
            <IonImg
              src='assets/images/badge.png'
              alt='Verified Badge'
              className={styles.badgeicon}
            />
          )}
        </IonCardTitle>
        {profile.distanceinmiles && <div className='milesaway'>{formatUserDistance(profile.distanceinmiles)}</div>}
      </IonCardHeader>
      <IonCardContent>
        <div className={styles.footicon}>
          <div
            role='button'
            tabIndex={0}
            onKeyUp={handleSkip}
            onClick={handleSkip}
            aria-label='Skip this profile'
          >
            <IonImg
              aria-hidden='true'
              src='assets/images/skipbtn.png'
              alt='Skip'
              className={styles.image}
            />
          </div>
          <div
            className={styles.profileBtn}
            tabIndex={0}
            role='link'
            onClick={routeToProfile}
            onKeyUp={routeToProfile}
          >
            View Profile
          </div>
          <div
            role='button'
            tabIndex={0}
            onClick={handleLike}
            onKeyUp={handleLike}
            aria-label='Like this profile'
          >
            <IonImg
              aria-hidden='true'
              src='/assets/images/likebtn.png'
              alt='Like'
              className={styles.image}
            />
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  )
}
