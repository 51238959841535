import {
  type ActionPerformed,
  PushNotifications,
  type PushNotificationSchema,
  type Token
} from '@capacitor/push-notifications'
import {
  IonImg,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonToast,
  useIonRouter
} from '@ionic/react'
import React, { type FC, useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router'

import { RevenueCatProvider } from '../../contexts/RevenueCatContext'
import { getSocket } from '../../services/socket'
import { IS_MOBILE } from '../../consts'

import Matches from '../Matches/Matches'
import MyLikes from '../MyLikes/MyLikes'
import LikedProfile from '../MyLikes/ProfileView'
import MyProfile from '../MyProfile/MyProfile'
import { saveUser } from '../MyProfile/services'
import Search from '../Search/Search'
import Settings from '../Settings/Settings'
import notifyInst from '../singleTon/notifyInst'

import { NotificationsUnreadCount } from './services'
import { maxBy } from 'lodash'
import FilterOptions from '../FilterOptions/FilterOptions'

const MainTabsInner: FC = () => {
  const router = useIonRouter()
  const [toast, setToast] = useState({
    color: 'secondary',
    isOpen: false,
    message: '',
    icon: ''
  })

  useEffect(() => {
    ;(async () => {
      const notificationCount = await NotificationsUnreadCount()
      const notification = maxBy(notificationCount.data, 'count')

      if (notification) {
        notifyInst.setPropertyValue('hasnotify', true)

        switch (notification.notification_type) {
          case 'LIKE':
            notifyInst.setPropertyValue('like', true)
            break
          case 'MATCH':
            notifyInst.setPropertyValue('match', true)
            break
          case 'MESSAGE':
            notifyInst.setPropertyValue('message', true)
            break
          case 'WELCOME':
          case 'ANNOUNCEMENT':
          default:
            break
        }
      }

      if (IS_MOBILE) {
        const pushNotificationPermissions = await PushNotifications.requestPermissions()

        console.log('[push notifications]', pushNotificationPermissions.receive)

        if (pushNotificationPermissions.receive === 'granted') {
          PushNotifications.addListener('registration', (token: Token) => {
            saveUser({ fcm_token: token.value })
          })
          PushNotifications.addListener('registrationError', () =>
            setToast({
              isOpen: true,
              color: 'danger',
              message: 'There was a problem registering push notifications!',
              icon: 'alert-outline'
            })
          )
          PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
            notifyInst.setPropertyValue('hasnotify', true)

            setToast({
              isOpen: true,
              color: 'secondary',
              message: notification.body ? notification.body : 'You have some notifications!',
              icon: 'notifications-outline'
            })
          })
          PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) =>
            setToast({
              isOpen: true,
              color: 'secondary',
              message:
                action.notification && action.notification.body
                  ? action.notification.body
                  : 'You have some notifications!',
              icon: 'notifications-outline'
            })
          )
          await PushNotifications.register()
        }
      }
    })()

    const socket = getSocket()

    socket
      // site-wide announcements from the admins
      .on('announcement', () => {
        notifyInst.setPropertyValue('hasnotify', true)
      })
      // chat messages with other users
      .on('message', () => {
        notifyInst.setPropertyValue('hasnotify', true)
        notifyInst.setPropertyValue('message', true)
      })
      // likes & matches with other users
      .on('MATCH', () => {
        notifyInst.setPropertyValue('hasnotify', true)
        notifyInst.setPropertyValue('match', true)
      })
      .on('LIKE', () => {
        notifyInst.setPropertyValue('hasnotify', true)
        notifyInst.setPropertyValue('like', true)
      })
      .connect()

    return () => {
      if (IS_MOBILE) {
        PushNotifications.removeAllListeners().catch(console.error)
      }

      socket.removeAllListeners().disconnect()
    }
  }, [])

  return (
    <>
      <IonToast
        color={toast.color}
        isOpen={toast.isOpen}
        message={toast.message}
        duration={1500}
      />
      <IonTabs>
        <IonRouterOutlet>
          <Redirect
            exact
            path='/home'
            to={`/home/user/own`}
          />
          {/*@ALERT - Change to `(/:profile_type)` if the router is upgraded! */}
          <Route
            path={`/home/user/:id/:view_type?`}
            render={() => <MyProfile />}
            exact={true}
          />
          <Route
            path='/home/search'
            render={() => <Search />}
            exact={true}
          />
          <Route
            path='/home/my_likes/id'
            render={() => <LikedProfile />}
            exact={true}
          />
          <Route
            path='/home/my_likes'
            render={() => <MyLikes />}
            exact={true}
          />
          <Route
            path='/home/matches'
            render={() => <Matches />}
            exact={true}
          />
          <Route
            path='/home/filterOptions'
            render={() => <FilterOptions />}
            exact={true}
          />
          <Route
            path='/home/settings'
            render={() => <Settings />}
            exact={true}
          />
        </IonRouterOutlet>
        <IonTabBar
          slot='bottom'
          role='navigation'
        >
          <IonTabButton
            tab='profile'
            href={`/home/user/own`}
          >
            {router?.routeInfo?.pathname === '/home/user/own' ? (
              <IonImg
                aria-hidden='true'
                src='assets/images/ProfileIconSelected.svg'
                className='tab-icon'
              />
            ) : (
              <IonImg
                aria-hidden='true'
                src='assets/images/ProfileIcon.svg'
                className='tab-icon'
              />
            )}
            <IonLabel>Profile</IonLabel>
          </IonTabButton>
          <IonTabButton
            tab='swipe'
            href='/home/search'
          >
            {router?.routeInfo?.pathname === '/home/search' ? (
              <IonImg
                aria-hidden='true'
                src='assets/images/SwipeIconSelected.svg'
                className='tab-icon'
              />
            ) : (
              <IonImg
                aria-hidden='true'
                src='assets/images/SwipeIcon.svg'
                className='tab-icon'
              />
            )}
            <IonLabel>Search</IonLabel>
          </IonTabButton>
          <IonTabButton
            tab='likes'
            href='/home/my_likes'
          >
            {router?.routeInfo?.pathname === '/home/my_likes' ? (
              <IonImg
                aria-hidden='true'
                src='assets/images/LikesIconSelected.svg'
                className='tab-icon'
              />
            ) : (
              <IonImg
                aria-hidden='true'
                src='assets/images/LikesIcon.svg'
                className='tab-icon'
              />
            )}
            <IonLabel>Likes</IonLabel>
          </IonTabButton>
          <IonTabButton
            tab='matches'
            href='/home/matches'
          >
            {router?.routeInfo?.pathname === '/home/matches' ? (
              <IonImg
                aria-hidden='true'
                src='assets/images/MatchesIconSelected.svg'
                className='tab-icon'
              />
            ) : (
              <IonImg
                aria-hidden='true'
                src='assets/images/MatchesIcon.svg'
                className='tab-icon'
              />
            )}
            <IonLabel>Matches</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </>
  )
}

const MainTabs: FC = () => (
  <RevenueCatProvider>
    <MainTabsInner />
  </RevenueCatProvider>
)

export default MainTabs
